import { Trans, useTranslation } from 'react-i18next';
import TripleCardComponent from './TripleCardComponent/TripleCardComponent';
import RankListComponent from './RankListComponent/RankListComponent';

function Home() {
  const { t } = useTranslation();

  return (
      <div className="Home">
        <div className="flex p-[50px]">
          <div className="w-[50%]">
            <h1 className="leading-[3rem] text-[3rem] font-black"><Trans i18nKey="HomeComponent_h1"></Trans></h1>
            <p className='w-[65%] text-lg font-medium mt-8'>{t('HomeComponent_subtitle')}</p>
            <button className="mt-8 bg-[#0A1128] text-white text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow">
              Commencer à voter
            </button>
          </div>
          <div className="w-[50%]">
              <TripleCardComponent/>
          </div>
        </div>
        <div className='p-[50px] flex gap-4 w-full'>
          <RankListComponent rankType="most_liked"/>
          <RankListComponent rankType="most_disliked"/>
          <RankListComponent rankType="most_trendy"/>
        </div>
      </div>
  );
}

export default Home;
