import { Link } from 'react-router-dom';
import './HeaderComponent.css';
//import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function HeaderComponent() {
  const { t } = useTranslation();

  /*const [searchPayload, setSearchPayload] = useState("");
  
  const actionSearch = function(){

  }*/

  return (
    <header className="bg-[#0A1128] h-[80px] flex justify-between items-center">
      <img src="/logo.svg" className="h-[70%]" alt="logo" />
      <nav className="flex ml-2 items-center gap-4 text-white">
          <Link to={"/most-liked"}>{t('HeaderComponent_most_liked')}</Link>
          <Link to={"/most-liked"}>{t('HeaderComponent_less_liked')}</Link>
          <Link to={"/most-liked"}>{t('HeaderComponent_trend')}</Link>
      </nav>
      <div>
      <div className="w-full max-w-sm min-w-[200px]">
        <input className="w-[150px] bg-white placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow" placeholder="Type here..."/>
        <button className="ml-2 bg-transparent text-white text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow">
        {t('ActionButton_search')}
        </button>
      </div>
      </div>
    </header>
  );
}

export default HeaderComponent;
