import { useTranslation } from 'react-i18next';
import RankListComponent from '../../components/RankListComponent/RankListComponent';
import TripleCardComponent from '../../components/TripleCardComponent/TripleCardComponent';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';


function PeoplePage() {
  const { t } = useTranslation();
  let { id } = useParams();

  useEffect(() => {
    if(!id){
      return;
    }
    
    let pId = id.split("-").at(-1);
    if(!isNaN(pId)){
      (async function() {
        try {
          const params = new URLSearchParams({"p_id": pId });
          const url = `https://api.people-ranking.com/api/getPeople?${params.toString()}`;
          const response = await fetch(url);
          
          const data = await response.json();
          console.log(data); 
        } catch (error) {
          console.error('Error while fetching: ', error);
        }
      })();
    } else {
      (async function() {
        try {
          const params = new URLSearchParams({"p_name": id });
          const url = `https://api.people-ranking.com/api/getPeople?${params.toString()}`;
          const response = await fetch(url);
          
          const data = await response.json();
          console.log(data);
        } catch (error) {
          console.error('Error while fetching: ', error);
        }
      })();
    }
  }, [id])

  return (
      <div className="Home">
        <div className="flex p-[50px]">
          <div className="w-[50%]">
            <h1 className="leading-[3rem] text-[3rem] font-black">ee</h1>
            <p className='w-[65%] text-lg font-medium mt-8'>{t('HomeComponent_subtitle')}</p>
            <button className="mt-8 bg-[#0A1128] text-white text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow">
              Commencer à voter
            </button>
          </div>
          <div className="w-[50%]">
              <TripleCardComponent/>
          </div>
        </div>
        <div className='p-[50px] flex gap-4 w-full'>
          <RankListComponent rankType="most_liked"/>
          <RankListComponent rankType="most_disliked"/>
          <RankListComponent rankType="most_trendy"/>
        </div>
      </div>
  );
}

export default PeoplePage;
