import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function RankListComponent(props) {

    const { t } = useTranslation();

    const [label, setLabel] = useState("");
    const [payload, setPayload] = useState([]);
    useEffect(() => {
        if(!props.rankType){
            return;
        }

        switch(props.rankType){
            case "most_liked":
                setLabel("RankListComponent_label_most_liked");
                break;
            case "most_disliked":
                setLabel("RankListComponent_label_most_disliked");
                break;
            case "most_trendy":
                setLabel("RankListComponent_label_most_trendy");
                break;
            default:
                setLabel("RankListComponent_label_most_liked");
                break;
        }

        //Todo implémenter logique de fetch by rank type
        
        //Dummy json en attendant
        setPayload()
    }, [props.rankType])

  return (
    <div className='flex flex-col w-full'>
        <div className='bg-[#0A1128] p-4 text-[#FFFFFF] text-center'>
            <h4>{t(label)}</h4>
        </div>
        <div>
            {payload}
        </div>
    </div>
  );
}

export default RankListComponent;
